import React from 'react'
import { node, object, string, bool } from 'prop-types'

// import { useThemeContext } from '@/component/Context/ThemeContext'

import A11yNavigation from '@/component/Primitive/A11yNavigation'
import Footer from '@/component/Common/Footer'
import Header from '@/component/Common/Header'
import Main from '@/component/Primitive/Main'
import SiteContainer from '@/component/Primitive/SiteContainer'
import ResponsiveSpacer from '@/component/Primitive/ResponsiveSpacer'
import CookieBanner from '@/component/Common/CookieBanner'
// import EmailSignUp from '@/component/Common/EmailSignUp'

const MainLayout = ({
  children,
  user,
  instance,
  searchTerm,
  groupedWidgetsLayout
}) => {
  // const theme = useThemeContext()
  return (
    <SiteContainer>
      <A11yNavigation>
        <a href="#content">Jump to main content</a>
        <a href="#navigation">Jump to primary navigation</a>
      </A11yNavigation>
      <Header instance={instance} user={user} searchTerm={searchTerm} />
      <Main groupedWidgetsLayout={groupedWidgetsLayout}>{children}</Main>
      <ResponsiveSpacer />
      <Footer />
      <CookieBanner />
    </SiteContainer>
  )
}

MainLayout.propTypes = {
  children: node.isRequired,
  user: object,
  instance: object,
  searchTerm: string,
  groupedWidgetsLayout: bool
}

export default MainLayout
